<template>
        <div class="popup save_popup mobileView_popup_body">
            <div class="mobileView_popup_body_head">
                <h3>Just a heads up...</h3>
            </div>
            <div class="mobileView_popup_body_item">
                <p>
                    Some of our features aren't available on mobile devices. Visit Colorcinch on a PC for a more feature-rich editing experience.
                </p>
            </div>

            <div class="mobileView_popup_button">
                    <a class="blue_btn" v-on:click="closeMobilePopup()">Got it, thanks</a>
            </div>
        </div>
</template>

<script>

    export default {
        name: "MobileViewInitPopup",
        props:{
            closeMobilePopup: Function
        },
        data: function() {
            return {};
        }
    };
</script>
<style scoped>
  .mobileView_popup_body {
    margin: 0px 36px;
    margin-top: 195px;
    color: #8C8C8C;
    background: #1D1D1D;
    padding: 24px;
  }

  .mobileView_popup_body_head {
    color: #FFFFFF;
    margin-bottom: 12px;
  }

  .mobileView_popup_body_item {
    font-size: 15px;
    line-height: 20px;
  }

  .mobileView_popup_button {
    display: flex;
    justify-content: flex-end;
    margin-top: 36px;
  }

  .mobileView_popup_button a {
    border-radius: 6px !important;
    text-transform: initial;
    padding: 10px 30px;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
  }
</style>
