<template>
    <div
            class="select_started_project"
            :class="selectedClass"
            @dragenter="handleDropImage"
            @dragover="handleDropImage"
            @dragleave="handleDropImage"
            @drop="dropUploadFile"
    >
        <transition name="fade">
            <div class="popup_wrpr active" v-if="mobileViewInitPopup && this.$store.state.isInitiateApp">
                <MobileViewInitPopup :closeMobilePopup="closeMobilePopup"/>
               <div class="pop_up_closer"></div>
            </div>
        </transition>
        <div class="select_started_photos">
            <div
                    tabIndex="0"
                    class="started_photos_item"
                    v-for="photo in startedPhotos"
                    v-bind:key="photo.thumb"
                    v-on:click="startFromPhotoAbove(photo.src)"
            >
                <img :src="photo.thumb" alt/>
            </div>
        </div>
    <p class="simple_p" :class="{'simple_p_mobile': isMobileView}">Select a photo above to get started, or upload your own</p>
        <form action>
            <label tabIndex="0" class="blue_btn file_input_btn" :class="{'file_input_btn_mobile': isMobileView}">
                Upload
                <input
                        v-custom-upload
                        type="file"
                        data-type="0"
                        accept="image/*"
                        ref="file"
                        @change="handleFileChange"
                />
            </label>
        </form>
            <AdPanel v-if="isRightAd && !isMobileView" :content="isRightAdContent" adPosition="right" />
            <AdPanel v-if="isBottomAd && !isMobileView" :content="isBottomAdContent" adPosition="bottom" />
            <AdPanel v-if="isMobileHomeAd && isMobileView" :content="isMobileHomeAdContent" adPosition="bottom-mobile" />
    </div>
</template>

<script>
    // @ is an alias to /src

    // import VueSlider from 'vue-slider-component';
    import AdPanel from "@/components/AdPanel.vue";
    import MobileViewInitPopup from "@/components/MobileViewInitPoppup";

    export default {
        name: "home",
        data: function () {
            return {
                showRegister:false,
                showPayFailed:false,
                mobileViewInitPopup: false,
                isMobileView: false,
                selectedClass: "",
                rangeSliderValue: 50,
                zoomValue: 100,
                startedPhotos: [
                    {src: require("@/assets/img/set-1.jpg"), thumb: require("@/assets/img/set-1.jpg")},
                    {src: require("@/assets/img/set-2.jpg"), thumb: require("@/assets/img/set-2.jpg")},
                    {src: require("@/assets/img/set-3.jpg"), thumb: require("@/assets/img/set-3.jpg")}
                ]
            };
        },
        components: {
            MobileViewInitPopup,
            AdPanel
        },
        computed: {
            isPremiumAccount() {
                return this.$store.state.isPremiumAccount;
            },
            isRightAd() {
                return (!this.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.right_ad?.is_right_ad) ?? false;
            },
            isRightAdContent() {
                if(!this.isRightAd) return ''
                if(window.innerWidth>=1600) return this.$store.state.ADINFO?.right_ad?.right_script_4k ?? this.$store.state.ADINFO?.right_ad?.right_script ?? '';
                return this.$store.state.ADINFO?.right_ad?.right_script ?? '' ;
            },
            isBottomAd() {
                return (!this.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.bottom_ad?.is_bottom_ad) ?? false;
            },
            isMobileHomeAd() {
                return (!this.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.mobile_home_ad?.is_mobile_home_ad) ?? false;
            },
            isBottomAdContent() {
                if(!this.isBottomAd) return ''
                return this.$store.state.ADINFO?.bottom_ad?.bottom_script ?? '' ;
            },
            isMobileHomeAdContent() {
                if(!this.isMobileHomeAd) return ''
                return this.$store.state.ADINFO?.mobile_home_ad?.mobile_home_script ?? '' ;
            },
            hash() {
                const hash = this.$route.hash.split('#')[1]
                if(hash) return `${hash[0].toUpperCase()}${hash.substring(1)}`.replace('-',' ');
                return null;
            },
        },
        async beforeMount() {
            this.isMobileView =  this.$store.state.isMobileView;
            this.mobileViewInitPopup = this.isMobileView;
            this.$store.dispatch("footerToggle", "disabled");
            this.$store.state.plans = window.cartoonize_plans ? window.cartoonize_plans : await fetch(`${this.$store.state.prefixURL}/get_plans`).then(res => res.json());
            this.$store.state.tipsComponents = JSON.parse((localStorage.getItem('tipsComponents') || "{}"));
        },
        mounted() {
            if (window.location.href.indexOf("pay/success") > -1) {
                let property = 'checkoutRegister';
                let value = 'true';
                this.$store.dispatch("popupsToggle", { property, value });
                window.sendinblue.track('pay_success');
            }
            if (window.location.href.indexOf("pay/failed") > -1) {
                this.showPayFailed = true;
                window.sendinblue.track('pay_failed');
                this.$router.push({ name: "home" });
            }

            var queryString = window.location.search,
                urlParams = new URLSearchParams(queryString);

            var image_in_url = urlParams.get("image");
            var redirect = urlParams.get("redirect");

            if (redirect && !this.$store.state.USER) {
              let property = 'signIn';
              let value = 'true';
              this.$store.dispatch("popupsToggle", { property, value });
            } else if (image_in_url) {
                this.startFromPhotoAbove(image_in_url);
            }

        },

        methods: {
            closeMobilePopup(){
                this.mobileViewInitPopup = false;
                this.$store.state.isInitiateApp = false;
            },
            handleDropImage(event) {
                // selected_started_project
                event.preventDefault();
                event.stopPropagation();

                this.selectedClass = "";
                if (event.type == "dragover" || event.type == "dragenter") {
                    this.selectedClass = "selected_started_project";
                }

      // console.log(event);
            },

            async dropUploadFile(event) {
                event.preventDefault();
                event.stopPropagation();

                const file =
                    event.dataTransfer.files && event.dataTransfer.files.length
                        ? event.dataTransfer.files[0]
                        : null;

                if (this.isCorrectTypeFile(file)) {
                    this.$store.dispatch("preloader", {
                        message: "Uploading...",
                        isShow: true
                    });
                    this.$store.state.openAbove = 1;
                    await this.$router.push({
                        path: this.$store.state.routerPrefixURL + "/image-manager",
                        hash: this.$route.hash
                    });
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = async () => {
                        await this.$store.dispatch("isInitialLib", true);
                        let dataUrl = await this.resizeImage(reader.result,file.type,this.isMobileView)
                        this.$lib.app.renderSprite({url: dataUrl}, async () => {
                            /* 1:To Set Border Scale*/
                            let value = this.$lib.app.zoomProcentage;
                            if (this.$lib.app.zoomProcentage != 1) {
                                this.$lib.app.scaleStage(1);
                                this.$lib.app.setZoomProcentage(1, true);
                            }
                            this.$lib.app.addTransparentPNGMask();
                            // this.$store.state.effectServerURL = await this.$lib.app.uploadFileToEffectsServer();
                            $(".centered-content").css({'transform': `scale(${value})`});
                            this.$lib.app.setFabricZoom(value);

                            this.$store.dispatch("zoomDashboard", value);

                            setTimeout(() => {
                                this.$lib.app.fabricCanvas.renderAll();
                                this.$store.dispatch("preloader", {});
                                this.$store.dispatch('fabricSlide', false);
                                this.$store.dispatch("sidebarPanelToggle", {
                                    panelName: this.hash ?? (this.isMobileView ? 'Effects' : 'Edit'),
                                    panelClass: "active"
                                });
                            }, 100);
                        });

                        // Commented due to Filter Issue and Image ZoomPercentage not correctly Set
                        // this.$lib.app.renderSprite({url: reader.result}, () => {
                        //     this.$store.dispatch("zoomDashboard", this.$lib.app.zoomProcentage);
                        //     this.$store.dispatch("zoomState", this.$lib.app.zoomProcentage);
                        //     this.$store.dispatch("preloader", {});
                        //     this.$lib.app.addTransparentPNGMask();
                        // });
                    };
                } else {
                    this.selectedClass = "";
                }
            },
            async resizeImage(result,filetype,isMobileView) {
                return new Promise((resolve, reject) => {
                    let image = new Image();
                    image.src = result;
                    image.onload = function() {
                        // access image size here
                        let canvas = document.createElement("canvas");
                        let ctx = canvas.getContext("2d");
                        ctx.drawImage(this, 0, 0);

                      let MAX_WIDTH = isMobileView ? 1920 : 2560;
                      let MAX_HEIGHT = isMobileView ? 1920 : 2560;

                        let width = this.width;
                        let height = this.height;

                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width;
                                width = MAX_WIDTH;
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        ctx = canvas.getContext("2d");
                        ctx.drawImage(this, 0, 0, width, height);
                        resolve(canvas.toDataURL(filetype));
                    }
                    image.onerror = reject
                })

            },

            isCorrectTypeFile(file) {
                if (file) {
                    if (
                        ["image/jpeg", "image/png", "image/gif"].find(
                            type => type == file.type
                        )
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },

            async convertBlobToBase64(blob) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onerror = reject;
                    reader.onload = () => {
                        resolve(reader.result);
                    };
                    reader.readAsDataURL(blob);
                });
            },



            async startFromPhotoAbove(url) {
                this.$store.dispatch("preloader", {
                    message: "Uploading...",
                    isShow: true
                });

                this.$store.state.openAbove = 1;
                this.$router.push({
                    path: this.$store.state.routerPrefixURL + "/image-manager",
                    hash: this.$route.hash
                });

                var response = await fetch(`${this.$store.state.prefixURL}${url}`, {
                    mode: 'no-cors'
                });
                var blob = await response.blob();
                var b64 = await this.convertBlobToBase64(blob);

                // this.$emit('initLib', true);
                setTimeout(async () => {
                    await this.$store.dispatch("isInitialLib", true);
                    this.$store.dispatch('fabricSlide', true);

                    this.$lib.app.renderSprite({url: b64}, async () => {
                        /* To Set Border Scale*/
                        let value = this.$lib.app.zoomProcentage;
                        if (this.$lib.app.zoomProcentage != 1) {
                            this.$lib.app.scaleStage(1);
                            this.$lib.app.setZoomProcentage(1, true);
                        }
                        this.$lib.app.addTransparentPNGMask();
                        // this.$store.state.effectServerURL = await this.$lib.app.uploadFileToEffectsServer();
                        $(".centered-content").css({'transform': `scale(${value})`});
                        $(".centered-content", '.fabricSlide').css({'transform': `scale(1)`});
                        this.$lib.app.fabricCanvas.setWidth(this.$lib.app.stage.width*value);
                        this.$lib.app.fabricCanvas.setHeight(this.$lib.app.stage.height*value);
                        this.$lib.app.fabricCanvas.setZoom(value);

                        this.$store.dispatch("zoomDashboard", value);
                        setTimeout(() => {
                            this.$lib.app.fabricCanvas.renderAll();
                            this.$store.dispatch("preloader", {});
                            this.$store.dispatch('fabricSlide', false);
                            this.$store.dispatch("sidebarPanelToggle", {
                                panelName: this.hash ?? (this.isMobileView ? 'Effects' : 'Edit'),
                                panelClass: "active"
                            });
                        }, 100);
                    });
                }, 500);
            },


            async handleFileChange(e) {

                await this.$store.dispatch("userFile", e.target.files[0]);
                this.$router.push({
                    path: this.$store.state.routerPrefixURL + "/image-manager",
                    hash: this.$route.hash
                });
            }
        }
    };
</script>

<style scoped>
    .selected_started_project {
        border: 2px dashed #249acf;
    }
    .blue_btn.file_input_btn {
      text-transform: uppercase;
    }
    .simple_p.simple_p_mobile {
      width: 253px;
      text-align: center;
    }
    .blue_btn.file_input_btn.file_input_btn_mobile {
      border-radius: 6px;
      padding: 14px 46px 30px 46px;
      text-transform: capitalize;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0;
    }
</style>
